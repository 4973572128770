<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pb-3">
        <v-card-title>Create account</v-card-title>
        <v-card-text>

          <v-row class="pb-3">
            <v-col>
              <p>Create an empty Google Ads account with standard settings including the latest tracking template from CMS. Also creates a domain mapping entry using the target host.</p>
              <p>Some settings are unable to be set via the Google API and therefore need to be changed manually after the account is created:</p>
              <ol>
                <li>In sub-MCC settings, change the "Google Ads conversion account" for this account to "This manager"</li>
                <li>Change the account "Ad suggestions" setting to "Don't automatically apply ad suggestions"</li>
              </ol>
            </v-col>
          </v-row>

          <v-form ref="form">
            <v-row>
              <v-col md="6">
                <v-autocomplete v-model="selectedSubMccAccountID" :items="subMccAccounts" item-text="name" item-value="account_id" label="Sub MCC"
                  required :rules="[v => !!v || 'Sub MCC is required']" prepend-icon="account_balance">
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6">
                <v-autocomplete v-model="selectedHost" :loading="loadingHosts" :items="hosts" item-text="top5_host_name" item-value="top5_host_name" label="Target host"
                  required :rules="[v => !!v || 'Target host is required']" prepend-icon="web">
                  <template v-slot:append-outer>
                    <v-menu>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-dots-vertical</v-icon>
                      </template>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item>
                            <v-list-item-title @click="refreshHosts()">Refresh hosts</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6">
                <v-text-field v-model="name" label="Name" required :rules="[v => !!v || 'Name is required']"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6">
                <v-btn color="primary" :loading="creating" @click="create">Create</v-btn>
                <v-fade-transition mode="out-in">
                  <v-btn color="primary darken-1" text v-show="showCreated">Created</v-btn>
                </v-fade-transition>
              </v-col>
            </v-row>

            <v-row v-show="showErrorAlert">
              <v-col md="6">
                <v-alert v-model="showErrorAlert" color="error" border="left" elevation="2" colored-border dismissible class="mt-4 ml-2 mr-2" icon="error" style="white-space: pre-line;">{{errorMsg}}</v-alert>
              </v-col>
            </v-row>

            <v-row v-show="showCreationAlert">
              <v-col md="6">
                <v-alert v-model="showCreationAlert" color="success" border="left" elevation="2" colored-border dismissible class="mt-4 ml-2 mr-2" icon="error" style="white-space: pre-line;">{{creationMsg}}</v-alert>
              </v-col>
            </v-row>

          </v-form>

        </v-card-text>
      </v-card>

    </v-col>
  </v-row>

</template>

<script>
import ccMixin from '@/mixins/contentCreator'

export default {
  name: 'GoogleAccount',

  data () {
    return {
      selectedSubMccAccountID: 0,
      selectedHost: '',
      hosts: [],
      loadingHosts: false,
      name: '',
      creating: false,
      showCreated: false,
      showErrorAlert: false,
      errorMsg: '',
      showCreationAlert: false,
      creationMsg: '',
    }
  },

  mixins: [ccMixin],

  computed: {
    accountCreateEndpoint () {
      return '/a/google/content-creator/account'
    },

    getHostsEndpoint () {
      return '/a/core/hosts?xfields=id,top5_host_name&xper_page=5000'
    },

    refreshHostsEndpoint () {
      return '/a/core/hosts/refresh'
    },

    subMccAccounts () {
      return this.$store.state.google.subMccAccounts
    },
  },

  components: {
  },

  methods: {
    create () {
      this.showErrorAlert = false
      this.showCreationAlert = false
      if (!this.$refs.form.validate()) {
        return false
      }

      this.creating = true

      var obj = {
        'sub_mcc_account_id': this.selectedSubMccAccountID,
        'target_host': this.selectedHost,
        'name': this.name,
      }
      var body = JSON.stringify(obj)

      this.$http.post(this.accountCreateEndpoint, body).then(resp => {
        if (resp.data.data.error_msgs) {
          this.errorMsg = resp.data.data.error_msgs.join('\n')
          this.showErrorAlert = true
        } else {
          if (resp.data.data.account_id) {
            // refresh internal account list
            this.$store.dispatch('google/loadAccounts')

            // show message
            this.creationMsg = 'Created account: ' + resp.data.data.account_id + '. Please change the manual settings as shown above.'
            this.showCreationAlert = true
            this.showCreated = true
            this.timer = setTimeout(() => { this.showCreated = false }, this.cc_buttonDisplayMS)
          }
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.destEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.creating = false)
    },

    getHosts () {
      this.loadingHosts = true

      var myURL = this.getHostsEndpoint

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.hosts = resp.data.data
        } else {
          this.hosts = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingHosts = false)
    },

    refreshHosts () {
      this.loadingHosts = true
      var myURL = this.refreshHostsEndpoint

      this.selectedHost = ''

      this.$http.post(myURL).then(resp => {
        this.getHosts()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingHosts = false)
    },
  },

  created: function () {
    this.getHosts()
  },
}
</script>

<style scoped>
</style>